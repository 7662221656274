.cardsHome {
  margin-top: 10rem;
  background-color: black;
  padding-bottom: -4px;
  color: #fff;
  width:100%;
  
}
.cardsHome>.row>div>.link{
  text-decoration: none;
}


.cardTitle {
  text-align: center;
  color: #fff;
 text-decoration: none;
font-size: 1rem;
}

.textCard {
  color:gray;
  height: auto;
  text-decoration:none;
}
.card {
  width: 30rem;
  background-color: black;
  
}
.card > .cardImg {
  border-radius: 70px;
}
@media screen and (max-width: 760px) {
  .cardsHome {
    margin-top: 6rem;
    margin-bottom: -5rem;
  }
  .card {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardImg {
    width: 60%;
    box-shadow: 8px 8px 50px 2px #48cce0;
  }
  .textCard {
    display: none;
  }
  .cardTitle {
    margin-top: -1rem;
  }
}
