.gulfood {
  display: grid;
  margin-top: -4rem;
  justify-content: center;
  align-items: center; z-index: 8;
}
.gulfood > .row > .gulfoodTitle {
  font-family: Verdana, sans-serif;
  color:gray;
  
 
}
.gulfoodImg {
  border-radius: 10px;
  position: relative;
  top:-8rem;
  left: 4rem;
  justify-content: center;
  align-items: center;
}
.textBold {
  font-weight: bold;
}
.gulfood > .row > div {
  background-color: black;
  color: white;
}
@media screen and (max-width: 760px) {
  .gulfood {
    text-align: center;
    padding:1.5rem;
  }
  .gulfood > .row > .gulfoodTitle > .gulfoodText {
    display: none;
  }
  .gulfood > .row > .gulfoodTitle {
    border:0.5px dotted gray;
    margin-left: -1rem;
  }
  .gulfoodImg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    border-radius: 50%;
    box-shadow: 4px 2px 3px lightskyblue;
    margin-bottom: 2rem;
    position: static;
  }
  .managerImg {
    margin-bottom: -150px;
  }
}
