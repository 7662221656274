.side-drawer {
    height: auto;
    background: transparent;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 4rem;
    left: 0;
    width: auto;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    border-radius: 50px;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  
  .side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .side-drawer li {
    margin: 0.5rem 0;
  }
  
  .side-drawer a {
    text-decoration: none;
    color: #f0bdf0;
    font-size: 1.2rem;
  }
  
  .side-drawer a:hover,
  .side-drawer a:active {
    color: #3fd1fa;
  }
  
  @media screen and (min-width: 759px) {
    .side-drawer {
      display: none;
    }
  }
  