.container{
    display:grid;
   
}
.textBazooka{
    display: flex;
    justify-content: center;
    align-items: center;

}
.textBazooka>p {
font-size: 1.5rem;
}
.titleMap{
   display: flex;
   justify-content: center;
   font-weight: bolder;
   font-size: larger;
}