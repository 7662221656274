.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(
    rgb(65, 63, 63),
    rgb(247, 74, 74),
    yellow
  ); /*header color*/
  height: 110px;
  position: relative;
  animation-name: example;
  animation-duration: 3s;
  animation-delay: 0;
  animation-fill-mode: both;
}

@keyframes example {
  from {
    top: -70px;
    background-color: rgb(249, 249, 242);
  }
  to {
    top: 20px;
    background-color: rgb(238, 238, 247);
  }
}

.toolbar_navigator {
  /*display: flex;
  align-items: center;*/
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}

/*logo animation*/
.toolbar_logo > a > img {
  margin-left: 1rem;
  outline: 1px solid #330a0a;
  transition: outline 1s ease 0s;
  width: 100px;
  transition: width 1s ease 0s;
  display: inline;
  border-radius: 50%;
}
.toolbar_logo > a > img:hover {
  outline: 10px solid #d4d4ce;
  width: 110px;
  z-index: 10;
}
/****/

.toolbar_logo a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 1.5rem;
}
.menu {
  margin-top: -70px;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.toolbar_navigation-items ul > li > .link {
  color: rgb(250, 245, 245);
  font-size: 1.5rem;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items link {
  color: black;
  text-decoration: none;
}

.toolbar_navigation-items link:hover,
.toolbar_navigation-items link:active {
  color: #c1d3f7;
}

@media screen and (max-width: 760px) {
  .toolbar_navigation-items {
    display: none;
  }
  .toolbar {
    height: 40px;
  }

  .navigate {
    background-color:#000; /*header koochik color*/
    color:#fff;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
  }
}
@media screen and (min-width: 759px) {
  .toggle-btn {
    display: none;
  }

  .toolbar_logo {
    margin-left: 0;
  }
}

.doge-group {
  margin-top: -30px;
  margin-bottom: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 3.5rem;
  background-color: black;
  color: #fff;
  animation: flicker 1.5s infinite alternate;
}
.doge-group>p{
  font-size: 3rem;
  margin-left:1rem;
  margin-top:1.3rem;
}
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #1b0606, 0 0 19px #fff, 0 0 40px rgb(0, 255, 247),
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px rgb(0, 213, 255), 0 0 150px rgb(255, 0, 64);
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}
@media screen and (max-width:760px){
  .doge-group{
    font-size: 1.5rem;
  }
  .doge-group>p{
    font-size: 1.5rem;
    margin-top:1rem;
    margin-left:0.5rem;
  }
  }

