.card-container {
  margin-top: -3rem;
}

.homePage {
  margin-bottom: 10rem;
}

.cardsHome {
  display: grid;
  justify-content: space-evenly;
  align-items: center;
}
.cardsHome > .row > .card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
.gulfood {
  background-color: black;
  color: white;
}

.section3Img {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vitaminCtitle {
  position: absolute;
  top: 4rem;
  left: 12rem;
  font-size: 2.5rem;
  font-weight: bolder;
  color: yellow;
}
@media screen and (max-width: 760px) {
  .section3Img {
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vitaminCtitle {
    font-size: 1rem;
    top: 1rem;
    left: 1rem;
  }
  .section3Img > img {
    height: 100%;
    width: 100%;
  }
}
