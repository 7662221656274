@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App{
  color:white;
  
}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
@media screen and (max-width: 760px) {
  .App{
    align-items: center;
   
  }
}
.App{
  background-color: black;
  
}
