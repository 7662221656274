 .carouselContainer {
  position: relative;
  top: 50px;
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  margin:2rem 0;
  background-color: black;
  color:white;
}
.carousel {
  object-fit: contain;
}
h3 {
  color: #000;
}
.img {
  height: auto;
  width:auto
}
.carousel { /*animation carousel */
  height: 100%;
 /* animation-name: c1;
  animation-duration: 4s;*/

}
/*@keyframes c1{
  0%{
    width:10px;
  }
  100%{
    width:100%
  }
}*/

@media screen and (max-width: 760px) {
.carousel-caption{display:none} 
.carousel{height:150px;
}
}

 