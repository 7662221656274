
.body{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color:white;
}
*{
 /* border:1px solid gray;*/

}
.main-container{
  background-color: black;
  color:white; 
}
