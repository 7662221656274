.container{ 
    display: grid;
    margin-top:100px;
    color:white;
    justify-content: center;
    align-items: center;
}
.title{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom:3rem;
}
@media screen and (max-width:760px){
    .title{
        font-size: 1rem;
        margin-top: -2rem;
    }
}