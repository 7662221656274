footer {
  position: relative;
  bottom: 0px;
  margin-top: 50px;
  width: 100%;
  background-color: #0d0d0e; /*footer color*/
  backdrop-filter: blur(5px);
  height: auto;
  text-align: center;
  padding: 10px;
  border: 2px dotted white;
  box-shadow: 0px 3px 4px 4px gray;
  z-index: 1;
}
.top {
  position: fixed;
  font-size: 45px;
  font-weight: bold;
  cursor: pointer;
  right: 0px;
  width: 70px;
  height: 70px;
  margin: 10px;
padding-top:0;
  box-shadow: 2px 4px 7px;
  border-radius: 50%;
  background-color: white;
}
.top > a {
  color: black;
 
}
.top > a:hover {
  color: black;
  font-weight: bold;
}
footer > .red a:hover {
  text-decoration: none;
  color: black;
}
footer > .black {
  background: #000;
  padding: 7px;
  color: white;
  font-size: large;
  font-weight: bold;
}
footer a {
  text-decoration: none;
  color: white;
}
footer a:hover {
  color: rgb(0, 191, 255);
  font-size: larger;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
ul li {
  list-style: none;
  margin: 0.5rem;
  padding: 0.5px;
  /*background-color: rgb(244, 149, 149);*/
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
}
.link {
  padding: 5px;
  margin: 0.5rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}
.link:hover {
  color: white;
  text-shadow: 3px 3px 8px 8px white;
  text-decoration: underline;
}
.navigate {
  width: 100%;
}
.black {
  background: #080808;
  font-size: 1.2rem;
}
.red {
  background-color: rgb(247, 4, 4);
  font-size: 1.2rem;
}
.yellow {
  background: rgb(247, 247, 2);
  margin-top: -20px;
}
.footerYellow {
  font-size: 1.2rem;
  color: black;
  background: yellow;
  margin-top: -25px;
}
.logo {
  border-radius: 50%;
}
