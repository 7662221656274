.containerAboutUs {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0px;
  display: grid;
  padding: 10px;
  animation-name: example1;
  animation-duration: 3s;
  animation-delay: 0;
  animation-fill-mode: both;
}

@keyframes example1 {
  from {
    top: -500px;
    background-color: rgb(249, 249, 242);
  }
  to {
    top: 100px;
    background-color: rgb(238, 238, 247);
  }
}
.containerAboutUs::before {
  content: '';
  background-image: url('../../Images/doge.jpg');
  background-size: cover;
  opacity: 0.3;
  position: absolute;
  top: 10px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
h1 {
  margin-top: 1.5rem;
  padding: 20px;
  margin-bottom: 1.5rem;
  color: #eff6ed;
  font-size: 3rem;
  line-height: 0.9;
  text-align: left;
  text-shadow: 10px 0px 8px rgb(242, 13, 13);
}
.textAboutUs {
  position: absolute;
  right: 20px;
  margin-top: -200px;
  padding: 20px;
  font-size: 1.5rem;
  color:gray;
  opacity: 1;
  text-decoration: dashed;
  text-shadow: 2px 0px 1px rgb(14, 14, 14);
  font-family: Georgia, serif;
}
.anime {
  animation-name: anime;
  animation-duration: 5s;
}
@keyframes anime {
  from {
    transform: scale(1.5, 1.5);
  }
}

@media screen and (max-width: 759px) {
  .textAboutUs {
    color: rgb(8, 8, 8);
    text-align: center;
    padding: 20px;
    position: relative;
    top:0;
    left:10px;
    display: block;
  }
}
