.container {
  display: grid;
  margin: 10px;
  margin-top: 4rem;
  
}
.container a{
  color:white;
  text-decoration: none;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contactCard {
  min-height: 2.3rem;
  font-size: 1.5rem;
  padding: 0.5rem;
}
.firstRow {
  margin: 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
}
.contactsIcon{
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.address{
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.5rem;
}

